const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
import axios from "utils/axios-interceptor";
import { API_URL } from "utils/constants";

const initialState = {
  userSettingsData: [],
  downloadModalOpen: false,
  instructionModalOpen: false,
  osSystem: "Windows",
  // loading: false,
  // sort_dict_users: {
  //   username: "asc",
  // },
  // filter_dict_user: {
  //   status: "active",
  // },
  // dialog: {
  //   openUsers: false,
  //   usersData: {},
  // },
  // usersViewLogsData: [],
  // rowsPerPageUsers: 15,
  // openAddUserModal: false,

  // dev data
  userNameFilter: [],
  loading: false,
  page_no: 1,
  page_size: 15,
  sort_dict: { created_at: "desc" },
  payload: {
    page_size: 15,
    page_no: 1,
    filter_dict: {},
    sort_dict: {
      username: "desc",
    },
  },
  admin_data: [],
  total_users: 0,
  dialog: {
    openAdmin: false,
    adminData: {},
  },
};

export const fetchUserSettingsData = createAsyncThunk(
  "fetchUserSettingsData",
  async (data, { dispatch }) => {
    try {
      const payload = {
        page_size: 15,
        page_no: 1,
        filter_dict: {},
        sort_dict: {
          username: "asc",
        },
      };
      if (data && data?.search && data?.search !== "") {
        payload["search"] = data.search;
      }
      const resp = await axios.post(`${API_URL}/user/view-organization-users`, payload);
      if (resp.data.data && resp.data.data.records) {
        const usernames = resp.data.data.records.map((record) => record.username);
        dispatch(setUserNameFilter(usernames));
      } else {
        console.error("error");
      }

      return resp;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
);

export const fetchUsersDataAsync = createAsyncThunk("users/fetchUserslData", async (data) => {
  // Define the string
  var string = `${localStorage.getItem("username")}:${localStorage.getItem("automation_token")}`;

  // Encode the String
  var encodedString = btoa(string);
  let payload = { ...data };
  if (data?.search && data?.search !== "") {
    payload["search"] = data.search;
  } else {
    delete payload["search"];
  }
  const resp = await axios.post(
    `${API_URL}/user/view-organization-users`,
    { ...payload },
    {
      headers: {
        Authorization: `Basic ${encodedString}`,
      },
    }
  );

  return {
    records: resp.data.data.records,
    total_users: resp.data.data.total_records,
    payloadData: { ...data },
  };

  // const info = await axios.post(`http://localhost:8080/users`, { ...payload });
  // console.log(info.data.response);

  // return {
  //   records: info.data.response.data.records,
  //   total_users: info.data.response.data.total_records,
  //   payloadData: { ...data },
  // };
});

export const deleteUserAsync = createAsyncThunk("users/deleteUser", async (id) => {
  // Define the string
  var string = `${localStorage.getItem("username")}:${localStorage.getItem("automation_token")}`;

  // Encode the String
  var encodedString = btoa(string);

  await axios.delete(`${API_URL}/user/delete-organization-user/${id}`, {
    headers: {
      Authorization: `Basic ${encodedString}`,
    },
  });

  // try {
  //   const info = await axios.delete(`http://localhost:8080/users/${id}`);
  //   console.log(info.data.response);
  // } catch (error) {}
});

const userSettingsSlice = createSlice({
  name: "user-settings",
  initialState,
  reducers: {
    setDownloadModalOpen: (state, action) => {
      state.downloadModalOpen = action.payload;
    },
    setInstructionModalOpen: (state, action) => {
      state.instructionModalOpen = action.payload;
    },
    setOsSystem: (state, action) => {
      state.osSystem = action.payload;
    },
    changeRowsPerPageUsers: (state, action) => {
      state.userSettingsData = [];
      state.rowsPerPageUsers = action.payload;
    },
    changeSortDictUsers: (state, action) => {
      state.sort_dict_users = action.payload;
    },
    changeFilter: (state, action) => {
      state.filter_dict = action.payload;
    },
    setDialogUsers: (state, action) => {
      state.dialog = action.payload;
    },
    setAddUserModal: (state, action) => {
      state.openAddUserModal = action.payload;
    },
    setUserNameFilter: (state, action) => {
      state.userNameFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserSettingsData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchUserSettingsData.fulfilled, (state, action) => {
      console.log("action", action.payload);
      state.loading = false;
      state.userSettingsData = action.payload.data.data.records;
    });
    builder.addCase(fetchUserSettingsData.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.loading = false;
    });

    builder.addCase(fetchUsersDataAsync.pending, (state, action) => {
      state.loading = true;
      state.admin_data = [];
    });
    builder.addCase(fetchUsersDataAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.admin_data = action.payload.records;
      state.payload = action.payload.payloadData;
      state.total_users = action.payload.total_users;
    });
    builder.addCase(fetchUsersDataAsync.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.loading = false;
    });
  },
});

export const {
  setDownloadModalOpen,
  setInstructionModalOpen,
  setOsSystem,
  setDialogUsers,
  changeRowsPerPageUsers,
  changePageNumberUsers,
  changeSortDictUsers,
  setAddUserModal,
  setUserNameFilter,
} = userSettingsSlice.actions;

export const admin_data = (state) => state.userSettingsSlice.admin_data;
export const user_loading = (state) => state.userSettingsSlice.loading;
export const user_payload = (state) => state.userSettingsSlice.payload;
export const total_users = (state) => state.userSettingsSlice.total_users;

export default userSettingsSlice.reducer;
