const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
import axios from "utils/axios-interceptor";
import { API_URL } from "utils/constants";

const initialState = {
  historyTunnelSessions: [],
  downloadModalOpen: false,
  instructionModalOpen: false,
  osSystem: "Windows",
  loading: false,
  // sort_dict_htunnel: {
  //   created_at: "desc",
  // },
  // filter_dict_hTunel: {
  //   status: "inactive",
  // },
  // dialog: {
  //   openTunnel: false,
  //   tunnelData: {},
  // },
  // tunnelViewLogsData: [],
  // rowsPerPageHistoryTunnel: 15,
  // dev data
  tunnel_test_loading: false,
  page_no_test: 1,
  page_size_test: 15,
  sort_dict_test: { created_at: "desc" },
  payload_test: {
    page_size: 15,
    page_no: 1,
    filter_dict: {
      status: "inactive",
    },
    sort_dict: {
      created_at: "desc",
    },
  },
  tunnel_data_test: [],
  total_tunnels_test: 0,
  enable_delete: false,
  dialog: {
    openTunnel: false,
    tunnelData: {},
  },
};

export const fetchHistoryTunnelData = createAsyncThunk("fetchHistoryTunnelData", async (data) => {
  // Define the string
  var string = `${localStorage.getItem("username")}:${localStorage.getItem("automation_token")}`;

  // Encode the String
  var encodedString = btoa(string);
  let payload = {
    page_size: data.page_size,
    page_no: data.page_no,
    filter_dict: data.filter_dict,
    sort_dict: data.sort_dict,
  };
  if (data?.search && data?.search !== "") payload["search"] = data.search;
  const resp = await axios.post(
    `${API_URL}/tunnel/list-connection`,
    { ...payload },
    {
      headers: {
        Authorization: `Basic ${encodedString}`,
      },
    }
  );
  return resp;
});

export const fetchHistoryTunnelDataAsync = createAsyncThunk(
  "tunnel/fetchHistoryTunnelData",
  async (data) => {
    // Define the string
    var string = `${localStorage.getItem("username")}:${localStorage.getItem("automation_token")}`;

    // Encode the String
    var encodedString = btoa(string);
    let payload = { ...data };
    if (data?.search && data?.search !== "") {
      console.log(data.search);
      payload["search"] = data.search;
    } else {
      delete payload["search"];
    }
    const response = await axios.post(
      `${API_URL}/tunnel/list-connection`,
      { ...payload },
      {
        headers: {
          Authorization: `Basic ${encodedString}`,
        },
      }
    );

    return {
      records: response.data.data.records,
      total_tunnels_test: response.data.data.total_tunnels,
      enable_delete: response.data.data.enable_delete,
      payloadData: { ...data },
    };

    // const response = await axios.post(`${API_URL}/tunnel/list-connection`, { ...payload });
    // console.log(response.data.response);

    // return {
    //   records: response.data.response.data.records,
    //   total_tunnels_test: response.data.response.data.total_tunnels,
    //   enable_delete: response.data.response.data.enable_delete,
    //   payloadData: { ...data },
    // };
  }
);

export const deleteTunnelAsync = createAsyncThunk("tunnel/deleteTunnel", async (payload) => {
  // Define the string
  var string = `${localStorage.getItem("username")}:${localStorage.getItem("automation_token")}`;

  // Encode the String
  var encodedString = btoa(string);

  let booleanValue = payload.page === "tunnel" ? "active" : "inactive";
  await axios.delete(`${API_URL}/tunnel/delete-tunnel/${booleanValue}`, {
    data: payload.data,
    headers: {
      Authorization: `Basic ${encodedString}`,
    },
  });

  // try {
  //   console.log(payload);
  //   let booleanValue = payload.page === "tunnel" ? "active" : "inactive";
  //   const info = await axios.delete(`${API_URL}/tunnel/delete-tunnel/${booleanValue}`, {
  //     data: payload.data,
  //   });
  //   console.log(info.data.response);
  // } catch (error) {}
});

export const tunnelLogsAsync = createAsyncThunk("tunnel/tunnelLogs", async (id) => {
  // Define the string
  var string = `${localStorage.getItem("username")}:${localStorage.getItem("automation_token")}`;

  // Encode the String
  var encodedString = btoa(string);
  const response = await axios.get(`${API_URL}/tunnel/tunnel-connection-logs/${id}`, {
    headers: {
      Authorization: `Basic ${encodedString}`,
    },
  });

  return {
    records: response.data.data,
    total_tunnels_test: response.data.data.length,
    payloadData: initialState.payload_test,
  };

  // const info = await axios.get(`${API_URL}/tunnel/tunnel-connection-logs/${id}`);
  // console.log(info.data.response.data);
  // return {
  //   records: info.data.response.data,
  //   total_tunnels_test: info.data.response.data.length,
  //   payloadData: initialState.payload_test,
  // };
});

const historyTunnelSlice = createSlice({
  name: "history-tunnel",
  initialState,
  reducers: {
    setDownloadModalOpen: (state, action) => {
      state.downloadModalOpen = action.payload;
    },
    setInstructionModalOpen: (state, action) => {
      state.instructionModalOpen = action.payload;
    },
    setOsSystem: (state, action) => {
      state.osSystem = action.payload;
    },
    setDialogTunnel: (state, action) => {
      state.dialog.openTunnel = action.payload.openTunnel;
      state.dialog.tunnelData = action.payload.tunnelData;
    },
    resetTunnelData: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHistoryTunnelDataAsync.pending, (state, action) => {
      state.loading = true;
      state.tunnel_test_loading = true;
      state.tunnel_data_test = [];
    });
    builder.addCase(fetchHistoryTunnelDataAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.tunnel_test_loading = false;
      state.tunnel_data_test = action.payload.records;
      state.payload_test = action.payload.payloadData;
      state.enable_delete = action.payload.enable_delete;
      state.total_tunnels_test = action.payload.total_tunnels_test;
    });
    builder.addCase(fetchHistoryTunnelDataAsync.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.loading = false;
      state.tunnel_test_loading = false;
    });
    builder.addCase(tunnelLogsAsync.pending, (state, action) => {
      state.loading = true;
      state.tunnel_test_loading = true;
      state.tunnel_data_test = [];
    });
    builder.addCase(tunnelLogsAsync.fulfilled, (state, action) => {
      console.log(action.payload);
      state.loading = false;
      state.tunnel_test_loading = false;
      state.tunnel_data_test = action.payload.records;
      state.payload_test = action.payload.payloadData;
      state.total_tunnels_test = action.payload.total_tunnels_test;
    });
    builder.addCase(tunnelLogsAsync.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.loading = false;
      state.tunnel_test_loading = false;
    });
  },
});

export const {
  setDownloadModalOpen,
  setInstructionModalOpen,
  setOsSystem,
  setDialogTunnel,
  changeRowsPerPageHistoryTunnel,
  changePageNumberTunnel,
  changeSortDictHistoryTunnel,
  resetTunnelData,
} = historyTunnelSlice.actions;

export const tunnel_data_test = (state) => state.historyTunnelSlice.tunnel_data_test;
export const tunnel_test_loading = (state) => state.historyTunnelSlice.tunnel_test_loading;
export const payload_test = (state) => state.historyTunnelSlice.payload_test;
export const total_tunnels_test = (state) => state.historyTunnelSlice.total_tunnels_test;
export const enable_delete = (state) => state.historyTunnelSlice.enable_delete;

export default historyTunnelSlice.reducer;
