import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "utils/axios-interceptor";
import { BROWSER_API } from "utils/constants";
import { API_URL } from "utils/constants";
import { filterUpdatedList } from "utils/helper";

const initialState = {
  loading: false,
  payload: {
    page_size: 15,
    page_no: 1,
    filter_dict: {
      status: ["aborted", "completed", "error", "timeout", "IdleTimeout"],
    },
    sort_dict: {
      created_at: "desc",
    },
  },
  selectedBrowserFilterVersions: [],
  build_sessions_data: [],
  total_records: 0,
};

export const fetchBuildsAsync = createAsyncThunk("build-details/fetchRecords", async (data) => {
  let payload = structuredClone(data);
  if (!payload.filter_dict.status) {
    payload.filter_dict.status = ["aborted", "completed", "error", "timeout", "IdleTimeout"];
  }
  if (data?.search && data?.search !== "") {
    payload["search"] = data.search;
  } else {
    delete payload["search"];
  }
  const urlParams = new URLSearchParams(location.search);
  const buildName = urlParams.get("buildName");
  payload.filter_dict.build_name = [buildName];

  const response = await axios.post(`${API_URL}/webdriver_session/sessions/history/get-records`, {
    ...payload,
  });

  return {
    records: response.data.data.records,
    total_records_test: response.data.data.total_records,
    enable_delete: response.data.data.enable_delete,
    payloadData: { ...data },
  };

  // const response = await axios.post(`${API_URL}/records`, { ...payload });

  // return {
  //   records: response.data.data.records,
  //   total_records_test: response.data.data.total_records,
  //   enable_delete: response.data.data.enable_delete,
  //   payloadData: { ...data },
  // };
});

export const fetchAllBuildsAsync = createAsyncThunk(
  "build-details/fetchAllRecords",
  async (data) => {
    let payload = structuredClone(data);
    if (!payload.filter_dict.status) {
      payload.filter_dict.status = ["aborted", "completed", "error", "timeout", "IdleTimeout"];
    }

    const urlParams = new URLSearchParams(location.search);
    const buildName = urlParams.get("buildName");
    payload.filter_dict.build_name = [buildName];

    const response = await axios.post(`${API_URL}/webdriver_session/sessions/history/get-records`, {
      ...payload,
    });

    const allBuilds = response.data.data;
    // dummy data needed for testing
    // const allBuilds = {
    //   records: [
    //     {
    //       browser: ["chrome", "119.0"],
    //       browser_version: "119.0",
    //       automation_type: "manual",
    //       org_name: "asd22",
    //       device_name: "Desktop",
    //       session_timeout: 120,
    //       idle_session_timeout: 7200,
    //       build_name: "build name",
    //       proxy_name: null,
    //       enable_proxy: false,
    //       id: "7d72962b-f06a-4aeb-9d17-3bc656310cc5",
    //       test_name: "test name",
    //       status: "timeout",
    //       status_message: "Session timeout",
    //       enable_video: false,
    //       enable_logs: true,
    //       duration: 124.457655,
    //       screen_resolution: "1920x1080",
    //       session_automation_type: "manual",
    //       created_at: 1715346332.040323,
    //       user_name: "admin",
    //       test_status: null,
    //       video_url:
    //         "https://asd22.cloudifytests.io/view-video?id=14058e1d702eac1fc36e698cfb500f03&video=false&logs=true",
    //       toolbar: {
    //         name: "browser-fw49v",
    //         ip: "10.42.1.82",
    //         session_id: "14058e1d702eac1fc36e698cfb500f03",
    //       },
    //     },
    //     {
    //       browser: ["chrome", "110.0"],
    //       browser_version: "110.0",
    //       automation_type: "manual",
    //       org_name: "asd22",
    //       device_name: "Desktop",
    //       session_timeout: 7200,
    //       idle_session_timeout: 7200,
    //       build_name: "build name",
    //       proxy_name: null,
    //       enable_proxy: false,
    //       id: "235138cd-0e5c-4a5e-a2c3-7b94c280719b",
    //       test_name: "final attempt",
    //       status: "aborted",
    //       status_message: "Session aborted",
    //       enable_video: true,
    //       enable_logs: true,
    //       duration: 47.620383,
    //       screen_resolution: "1920x1080",
    //       session_automation_type: "manual",
    //       created_at: 1715344149.619629,
    //       user_name: "admin",
    //       test_status: "pass",
    //       video_url:
    //         "https://asd22.cloudifytests.io/view-video?id=d85014e7a219105b22d16903e5d2be51&video=true&logs=true",
    //       toolbar: {
    //         name: "browser-z6kbm",
    //         ip: "10.42.1.81",
    //         session_id: "d85014e7a219105b22d16903e5d2be51",
    //       },
    //     },
    //     {
    //       browser: ["chrome", "110.0"],
    //       browser_version: "110.0",
    //       automation_type: "manual",
    //       org_name: "asd22",
    //       device_name: "Desktop",
    //       session_timeout: 7200,
    //       idle_session_timeout: 7200,
    //       build_name: "build name",
    //       proxy_name: null,
    //       enable_proxy: false,
    //       id: "524fed2a-01f9-4bb8-b69e-1a6459607370",
    //       test_name: "attempt 2",
    //       status: "aborted",
    //       status_message: "Session aborted",
    //       enable_video: true,
    //       enable_logs: true,
    //       duration: 44.625082,
    //       screen_resolution: "1920x1080",
    //       session_automation_type: "manual",
    //       created_at: 1715342891.794024,
    //       user_name: "admin",
    //       test_status: "pass",
    //       video_url:
    //         "https://asd22.cloudifytests.io/view-video?id=4c52d92883ec44b00847544a16596f82&video=true&logs=true",
    //       toolbar: {
    //         name: "browser-tlc2g",
    //         ip: "10.42.1.79",
    //         session_id: "4c52d92883ec44b00847544a16596f82",
    //       },
    //     },
    //     {
    //       browser: ["chrome", "110.0"],
    //       browser_version: "110.0",
    //       automation_type: "manual",
    //       org_name: "asd22",
    //       device_name: "Desktop",
    //       session_timeout: 7200,
    //       idle_session_timeout: 7200,
    //       build_name: "build name",
    //       proxy_name: null,
    //       enable_proxy: false,
    //       id: "41a53844-fdbf-4ecc-a436-d4b5559f7e2b",
    //       test_name: "attempt 1",
    //       status: "aborted",
    //       status_message: "Session aborted",
    //       enable_video: true,
    //       enable_logs: true,
    //       duration: 5.871497,
    //       screen_resolution: "1920x1080",
    //       session_automation_type: "manual",
    //       created_at: 1715342368.81771,
    //       user_name: "admin",
    //       test_status: "pass",
    //       video_url:
    //         "https://asd22.cloudifytests.io/view-video?id=434c3e5dcb6d015ec0837ddd0538975f&video=true&logs=true",
    //       toolbar: {
    //         name: "browser-p6v6q",
    //         ip: "10.42.1.77",
    //         session_id: "434c3e5dcb6d015ec0837ddd0538975f",
    //       },
    //     },
    //     {
    //       browser: ["chrome", "110.0"],
    //       browser_version: "110.0",
    //       automation_type: "manual",
    //       org_name: "asd22",
    //       device_name: "Desktop",
    //       session_timeout: 7200,
    //       idle_session_timeout: 7200,
    //       build_name: "build name",
    //       proxy_name: null,
    //       enable_proxy: false,
    //       id: "34c8c341-cf27-4217-83b3-e5eeb120ccad",
    //       test_name: "test name",
    //       status: "aborted",
    //       status_message: "Session aborted",
    //       enable_video: true,
    //       enable_logs: true,
    //       duration: 30.923419,
    //       screen_resolution: "1920x1080",
    //       session_automation_type: "manual",
    //       created_at: 1715341246.893165,
    //       user_name: "admin",
    //       test_status: "pass",
    //       video_url:
    //         "https://asd22.cloudifytests.io/view-video?id=7838a2354c3c3d615c48a4d93f1bff5d&video=true&logs=true",
    //       toolbar: {
    //         name: "browser-ngcfr",
    //         ip: "10.42.1.75",
    //         session_id: "7838a2354c3c3d615c48a4d93f1bff5d",
    //       },
    //     },
    //     {
    //       browser: ["chrome", "110.0"],
    //       browser_version: "110.0",
    //       automation_type: "manual",
    //       org_name: "asd22",
    //       device_name: "Desktop",
    //       session_timeout: 7200,
    //       idle_session_timeout: 7200,
    //       build_name: "build name",
    //       proxy_name: null,
    //       enable_proxy: false,
    //       id: "e57309a0-1680-46c2-87b2-c3b87a1528ac",
    //       test_name: "test name",
    //       status: "aborted",
    //       status_message: "Session aborted",
    //       enable_video: true,
    //       enable_logs: true,
    //       duration: 24.36933,
    //       screen_resolution: "1920x1080",
    //       session_automation_type: "manual",
    //       created_at: 1715340484.602156,
    //       user_name: "admin",
    //       test_status: "pass",
    //       video_url:
    //         "https://asd22.cloudifytests.io/view-video?id=ddc7b359f1f22917b194d896fa52bd18&video=true&logs=true",
    //       toolbar: {
    //         name: "browser-kdz9g",
    //         ip: "10.42.1.73",
    //         session_id: "ddc7b359f1f22917b194d896fa52bd18",
    //       },
    //     },
    //     {
    //       browser: ["chrome", "110.0"],
    //       browser_version: "110.0",
    //       automation_type: "manual",
    //       org_name: "asd22",
    //       device_name: "Desktop",
    //       session_timeout: 7200,
    //       idle_session_timeout: 7200,
    //       build_name: "build name",
    //       proxy_name: null,
    //       enable_proxy: false,
    //       id: "55fd905c-0809-424e-9def-120d8afe5b48",
    //       test_name: "test name",
    //       status: "aborted",
    //       status_message: "Session aborted",
    //       enable_video: true,
    //       enable_logs: true,
    //       duration: 70.173159,
    //       screen_resolution: "1920x1080",
    //       session_automation_type: "manual",
    //       created_at: 1715336685.264484,
    //       user_name: "admin",
    //       test_status: "pass",
    //       video_url:
    //         "https://asd22.cloudifytests.io/view-video?id=833fa22dd5ebc8fd53aceb4a1a0fb625&video=true&logs=true",
    //       toolbar: {
    //         name: "browser-br7lt",
    //         ip: "10.42.1.70",
    //         session_id: "833fa22dd5ebc8fd53aceb4a1a0fb625",
    //       },
    //     },
    //     {
    //       browser: ["chrome", "110.0"],
    //       browser_version: "110.0",
    //       automation_type: "manual",
    //       org_name: "asd22",
    //       device_name: "Desktop",
    //       session_timeout: 7200,
    //       idle_session_timeout: 7200,
    //       build_name: "build name",
    //       proxy_name: null,
    //       enable_proxy: false,
    //       id: "55fd905c-0809-424e-9def-120d8afe5b48",
    //       test_name: "test name",
    //       status: "aborted",
    //       status_message: "Session aborted",
    //       enable_video: true,
    //       enable_logs: true,
    //       duration: 70.173159,
    //       screen_resolution: "1920x1080",
    //       session_automation_type: "manual",
    //       created_at: 1715336685.264484,
    //       user_name: "admin",
    //       test_status: "pass",
    //       video_url:
    //         "https://asd22.cloudifytests.io/view-video?id=833fa22dd5ebc8fd53aceb4a1a0fb625&video=true&logs=true",
    //       toolbar: {
    //         name: "browser-br7lt",
    //         ip: "10.42.1.70",
    //         session_id: "833fa22dd5ebc8fd53aceb4a1a0fb625",
    //       },
    //     },
    //     {
    //       browser: ["chrome", "110.0"],
    //       browser_version: "110.0",
    //       automation_type: "manual",
    //       org_name: "asd22",
    //       device_name: "Desktop",
    //       session_timeout: 7200,
    //       idle_session_timeout: 7200,
    //       build_name: "build name",
    //       proxy_name: null,
    //       enable_proxy: false,
    //       id: "55fd905c-0809-424e-9def-120d8afe5b48",
    //       test_name: "test name",
    //       status: "aborted",
    //       status_message: "Session aborted",
    //       enable_video: true,
    //       enable_logs: true,
    //       duration: 70.173159,
    //       screen_resolution: "1920x1080",
    //       session_automation_type: "manual",
    //       created_at: 1715336685.264484,
    //       user_name: "admin",
    //       test_status: "pass",
    //       video_url:
    //         "https://asd22.cloudifytests.io/view-video?id=833fa22dd5ebc8fd53aceb4a1a0fb625&video=true&logs=true",
    //       toolbar: {
    //         name: "browser-br7lt",
    //         ip: "10.42.1.70",
    //         session_id: "833fa22dd5ebc8fd53aceb4a1a0fb625",
    //       },
    //     },
    //     {
    //       browser: ["chrome", "110.0"],
    //       browser_version: "110.0",
    //       automation_type: "manual",
    //       org_name: "asd22",
    //       device_name: "Desktop",
    //       session_timeout: 7200,
    //       idle_session_timeout: 7200,
    //       build_name: "build name",
    //       proxy_name: null,
    //       enable_proxy: false,
    //       id: "55fd905c-0809-424e-9def-120d8afe5b48",
    //       test_name: "test name",
    //       status: "aborted",
    //       status_message: "Session aborted",
    //       enable_video: true,
    //       enable_logs: true,
    //       duration: 70.173159,
    //       screen_resolution: "1920x1080",
    //       session_automation_type: "manual",
    //       created_at: 1715336685.264484,
    //       user_name: "admin",
    //       test_status: "pass",
    //       video_url:
    //         "https://asd22.cloudifytests.io/view-video?id=833fa22dd5ebc8fd53aceb4a1a0fb625&video=true&logs=true",
    //       toolbar: {
    //         name: "browser-br7lt",
    //         ip: "10.42.1.70",
    //         session_id: "833fa22dd5ebc8fd53aceb4a1a0fb625",
    //       },
    //     },
    //     {
    //       browser: ["chrome", "110.0"],
    //       browser_version: "110.0",
    //       automation_type: "manual",
    //       org_name: "asd22",
    //       device_name: "Desktop",
    //       session_timeout: 7200,
    //       idle_session_timeout: 7200,
    //       build_name: "build name",
    //       proxy_name: null,
    //       enable_proxy: false,
    //       id: "55fd905c-0809-424e-9def-120d8afe5b48",
    //       test_name: "test name",
    //       status: "aborted",
    //       status_message: "Session aborted",
    //       enable_video: true,
    //       enable_logs: true,
    //       duration: 70.173159,
    //       screen_resolution: "1920x1080",
    //       session_automation_type: "manual",
    //       created_at: 1715336685.264484,
    //       user_name: "admin",
    //       test_status: "pass",
    //       video_url:
    //         "https://asd22.cloudifytests.io/view-video?id=833fa22dd5ebc8fd53aceb4a1a0fb625&video=true&logs=true",
    //       toolbar: {
    //         name: "browser-br7lt",
    //         ip: "10.42.1.70",
    //         session_id: "833fa22dd5ebc8fd53aceb4a1a0fb625",
    //       },
    //     },
    //     {
    //       browser: ["chrome", "110.0"],
    //       browser_version: "110.0",
    //       automation_type: "manual",
    //       org_name: "asd22",
    //       device_name: "Desktop",
    //       session_timeout: 7200,
    //       idle_session_timeout: 7200,
    //       build_name: "build name",
    //       proxy_name: null,
    //       enable_proxy: false,
    //       id: "55fd905c-0809-424e-9def-120d8afe5b48",
    //       test_name: "test name",
    //       status: "aborted",
    //       status_message: "Session aborted",
    //       enable_video: true,
    //       enable_logs: true,
    //       duration: 70.173159,
    //       screen_resolution: "1920x1080",
    //       session_automation_type: "manual",
    //       created_at: 1715336685.264484,
    //       user_name: "admin",
    //       test_status: "pass",
    //       video_url:
    //         "https://asd22.cloudifytests.io/view-video?id=833fa22dd5ebc8fd53aceb4a1a0fb625&video=true&logs=true",
    //       toolbar: {
    //         name: "browser-br7lt",
    //         ip: "10.42.1.70",
    //         session_id: "833fa22dd5ebc8fd53aceb4a1a0fb625",
    //       },
    //     },
    //     {
    //       browser: ["chrome", "110.0"],
    //       browser_version: "110.0",
    //       automation_type: "manual",
    //       org_name: "asd22",
    //       device_name: "Desktop",
    //       session_timeout: 7200,
    //       idle_session_timeout: 7200,
    //       build_name: "build name",
    //       proxy_name: null,
    //       enable_proxy: false,
    //       id: "55fd905c-0809-424e-9def-120d8afe5b48",
    //       test_name: "test name",
    //       status: "aborted",
    //       status_message: "Session aborted",
    //       enable_video: true,
    //       enable_logs: true,
    //       duration: 70.173159,
    //       screen_resolution: "1920x1080",
    //       session_automation_type: "manual",
    //       created_at: 1715336685.264484,
    //       user_name: "admin",
    //       test_status: "pass",
    //       video_url:
    //         "https://asd22.cloudifytests.io/view-video?id=833fa22dd5ebc8fd53aceb4a1a0fb625&video=true&logs=true",
    //       toolbar: {
    //         name: "browser-br7lt",
    //         ip: "10.42.1.70",
    //         session_id: "833fa22dd5ebc8fd53aceb4a1a0fb625",
    //       },
    //     },
    //     {
    //       browser: ["chrome", "110.0"],
    //       browser_version: "110.0",
    //       automation_type: "manual",
    //       org_name: "asd22",
    //       device_name: "Desktop",
    //       session_timeout: 7200,
    //       idle_session_timeout: 7200,
    //       build_name: "build name",
    //       proxy_name: null,
    //       enable_proxy: false,
    //       id: "55fd905c-0809-424e-9def-120d8afe5b48",
    //       test_name: "test name",
    //       status: "aborted",
    //       status_message: "Session aborted",
    //       enable_video: true,
    //       enable_logs: true,
    //       duration: 70.173159,
    //       screen_resolution: "1920x1080",
    //       session_automation_type: "manual",
    //       created_at: 1715336685.264484,
    //       user_name: "admin",
    //       test_status: "pass",
    //       video_url:
    //         "https://asd22.cloudifytests.io/view-video?id=833fa22dd5ebc8fd53aceb4a1a0fb625&video=true&logs=true",
    //       toolbar: {
    //         name: "browser-br7lt",
    //         ip: "10.42.1.70",
    //         session_id: "833fa22dd5ebc8fd53aceb4a1a0fb625",
    //       },
    //     },
    //     {
    //       browser: ["chrome", "110.0"],
    //       browser_version: "110.0",
    //       automation_type: "manual",
    //       org_name: "asd22",
    //       device_name: "Desktop",
    //       session_timeout: 7200,
    //       idle_session_timeout: 7200,
    //       build_name: "build name",
    //       proxy_name: null,
    //       enable_proxy: false,
    //       id: "55fd905c-0809-424e-9def-120d8afe5b48",
    //       test_name: "test name",
    //       status: "aborted",
    //       status_message: "Session aborted",
    //       enable_video: true,
    //       enable_logs: true,
    //       duration: 70.173159,
    //       screen_resolution: "1920x1080",
    //       session_automation_type: "manual",
    //       created_at: 1715336685.264484,
    //       user_name: "admin",
    //       test_status: "pass",
    //       video_url:
    //         "https://asd22.cloudifytests.io/view-video?id=833fa22dd5ebc8fd53aceb4a1a0fb625&video=true&logs=true",
    //       toolbar: {
    //         name: "browser-br7lt",
    //         ip: "10.42.1.70",
    //         session_id: "833fa22dd5ebc8fd53aceb4a1a0fb625",
    //       },
    //     },
    //     {
    //       browser: ["chrome", "110.0"],
    //       browser_version: "110.0",
    //       automation_type: "manual",
    //       org_name: "asd22",
    //       device_name: "Desktop",
    //       session_timeout: 7200,
    //       idle_session_timeout: 7200,
    //       build_name: "build name",
    //       proxy_name: null,
    //       enable_proxy: false,
    //       id: "55fd905c-0809-424e-9def-120d8afe5b48",
    //       test_name: "test name",
    //       status: "aborted",
    //       status_message: "Session aborted",
    //       enable_video: true,
    //       enable_logs: true,
    //       duration: 70.173159,
    //       screen_resolution: "1920x1080",
    //       session_automation_type: "manual",
    //       created_at: 1715336685.264484,
    //       user_name: "admin",
    //       test_status: "pass",
    //       video_url:
    //         "https://asd22.cloudifytests.io/view-video?id=833fa22dd5ebc8fd53aceb4a1a0fb625&video=true&logs=true",
    //       toolbar: {
    //         name: "browser-br7lt",
    //         ip: "10.42.1.70",
    //         session_id: "833fa22dd5ebc8fd53aceb4a1a0fb625",
    //       },
    //     },
    //   ],
    //   test_status_fail: 1,
    //   test_status_not_set: 10,
    //   test_status_pass: 133,
    //   total_pages: 1,
    //   total_records: 353,
    // };
    return {
      allBuilds,
    };
  }

  // const response = await axios.post(`${API_URL}/records`, { ...payload });
  // const allBuilds = response.data.data.records;

  // return {
  //   allBuilds,
  // };
  // }
);

export const fetchBrowserVersions = createAsyncThunk("build-details/browserVersions", async () => {
  const response = await axios.get(`${BROWSER_API}/test.json`);
  return response.data;
});

export const fetchTodos = createAsyncThunk("fetchTodos", async (data, thunkAPI) => {
  try {
    let payload = {
      page_size: data.page_size,
      page_no: data.page_no,
      filter_dict: data.filter_dict,
      sort_dict: data.sort_dict,
    };

    if (data?.search && data?.search !== "") payload["search"] = data.search;
    const resp = await axios.post(`${API_URL}/webdriver_session/sessions/history/get-records`, {
      ...payload,
    });

    thunkAPI.dispatch(setEnableDelete(resp?.data?.data?.enable_delete));

    return resp;
  } catch (error) {
    console.error("Error fetching todos:", error);
    throw error;
  }
});

const buildSessionsSlice = createSlice({
  name: "buildSessions",
  initialState,
  reducers: {
    changeRowsPerPage: (state, action) => {
      state.sessions = [];
      state.rowsPerPage = action.payload;
    },
    changePageNumber: (state, action) => {
      state.sessions = [];
      state.pageNumber = action.payload;
    },
    changeSortDict: (state, action) => {
      state.sort_dict = action.payload;
    },
    changeSearch: (state, action) => {
      state.search = action.payload;
    },
    changeFilter: (state, action) => {
      state.filter_dict = action.payload;
    },
    changeLoading: (state, action) => {
      state.loading = action.payload;
    },
    removeSessions: (state) => {
      state.sessions = [];
    },
    setResetFilterHistorySessions: (state, action) => {
      state.resetFilterHistorySessions = action.payload;
    },
    setEnableDelete: (state, action) => {
      state.enableDelete = action.payload;
    },
    setBrowsers: (state, action) => {
      state.browsers = action.payload;
    },
    setBrowserFilterList: (state, action) => {
      // let browserList = current(state.browserFilterList);
      // let updatedBrowserList = action.payload.reduce((accumulator, item) => {
      //   return (accumulator = [...accumulator, ...browserList[item]]);
      // }, []);
      // let updatedArray = Array.from(new Set(updatedBrowserList));
      // state.selectedBrowserFilterVersions = updatedArray;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBuildsAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchBuildsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.build_sessions_data = action.payload.records;
      state.payload = action.payload.payloadData;
      state.total_records = action.payload.total_records_test;
    });
    builder.addCase(fetchBuildsAsync.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.loading = false;
    });
    builder.addCase(fetchBrowserVersions.fulfilled, (state, action) => {
      state.browserFilterList = action.payload;
      if (JSON.parse(sessionStorage.getItem("filter_dict_reports"))) {
        let filterData = JSON.parse(sessionStorage.getItem("filter_dict_reports"));
        let filter_dict = filterUpdatedList(filterData);
        if (filter_dict.browser_name) {
          let browserList = action.payload;
          let updatedBrowserList = filter_dict.browser_name.reduce((accumulator, item) => {
            return (accumulator = [...accumulator, ...browserList[item]]);
          }, []);
          let updatedArray = Array.from(new Set(updatedBrowserList));
          state.selectedBrowserFilterVersions = updatedArray;
        }
      }
    });
    builder.addCase(fetchTodos.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchTodos.fulfilled, (state, action) => {
      state.loading = false;
      state.sessions = action.payload.data.data.records;
      state.count = action.payload.data.data.total_records;
    });
    builder.addCase(fetchTodos.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.loading = false;
    });
  },
});

export const { setBrowserFilterList } = buildSessionsSlice.actions;
export const build_loading = (state) => state.buildSessionsSlice.loading;
export const build_payload_details = (state) => state.buildSessionsSlice.payload;
export const build_sessions_data = (state) => state.buildSessionsSlice.build_sessions_data;
export const build_total_records = (state) => state.buildSessionsSlice.total_records;
export const hs_selected_browser_versions = (state) =>
  state.buildSessionsSlice.selectedBrowserFilterVersions;

export default buildSessionsSlice.reducer;
