import axios from "utils/axios-interceptor";
import { API_URL } from "utils/constants";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
  sessions_loading: false,
  sessions_data: [],
  sessions_payload: {
    page_size: 15,
    page_no: 1,
    filter_dict: {
      status: ["running"],
    },
    sort_dict: {
      created_at: "desc",
    },
  },
  enable_delete: false,
  total_sessions_records: 0,
  dialog: {
    open: false,
    data: null,
  },
};

export const fetchSessionsRecordsAsync = createAsyncThunk("sessions/fetchRecords", async (data) => {
  console.log("testing");
  let payload = { ...data };
  if (!payload.filter_dict.status) {
    payload.filter_dict.status = ["running", "aborted", "completed", "error"];
  }
  if (data?.search && data?.search !== "") {
    payload["search"] = data.search;
  } else {
    delete payload["search"];
  }
  const response = await axios.post(`${API_URL}/webdriver_session/sessions/history/get-records`, {
    ...payload,
  });

  return {
    records: response.data.data.records,
    total_sessions_records: response.data.data.total_records,
    enable_delete: response.data.data.enable_delete,
    payloadData: { ...data },
  };

  // const response = await axios.post(`http://localhost:8080/records`, { ...payload });
  // console.log(response.data);

  // return {
  //   records: response.data.records,
  //   total_sessions_records: response.data.total_records,
  //   enable_delete: response.data.enable_delete,
  //   payloadData: { ...data },
  // };
});

const sessionsSlice = createSlice({
  name: "sessions",
  initialState,
  reducers: {
    setSessionsDialog: (state, action) => {
      state.dialog = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSessionsRecordsAsync.fulfilled, (state, action) => {
      console.log({ payload: action.payload });
      if (
        JSON.stringify(JSON.parse(JSON.stringify(state)).sessions_data) !==
        JSON.stringify(action.payload.records)
      ) {
        state.sessions_data = action.payload.records;
      }
      state.sessions_payload = action.payload.payloadData;
      state.enable_delete = action.payload.enable_delete;
      state.total_sessions_records = action.payload.total_sessions_records;
    });
  },
});

export const { setSessionsDialog } = sessionsSlice.actions;
export const sessions_dialog = (state) => state.sessions.dialog;
export const sessions_data = (state) => state.sessions.sessions_data;
export const sessions_loading = (state) => state.sessions.sessions_loading;
export const sessions_payload = (state) => state.sessions.sessions_payload;
export const total_sessions_records = (state) => state.sessions.total_sessions_records;
export const sessions_enable_delete = (state) => state.sessions.enable_delete;

export default sessionsSlice.reducer;
