/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from "@mui/material/Icon";
import React from "react";
import { FLAVOUR2 } from "utils/constants";
import { authRoutes } from "utils/constants";

// Material Dashboard 2 React layouts
const Dashboard = React.lazy(() => import("layouts/dashboard"));

const Tables = React.lazy(() => import("layouts/tables"));
const Billing = React.lazy(() => import("layouts/billing"));
const RTL = React.lazy(() => import("layouts/rtl"));
const Notifications = React.lazy(() => import("layouts/notifications"));
const Profile = React.lazy(() => import("layouts/profile"));
const SignIn = React.lazy(() => import("layouts/authentication/sign-in"));
const SignUp = React.lazy(() => import("layouts/authentication/sign-up"));
const Sessions = React.lazy(() => import("layouts/sessions/Sessions"));
const HistorySessions = React.lazy(() => import("layouts/histoy-sessions/HistorySessions"));
const Tunnel = React.lazy(() => import("layouts/tunnel/Tunnel"));
const HistoryTunnel = React.lazy(() => import("layouts/history-tunnel/HistoryTunnel"));
const ViewVnc = React.lazy(() => import("layouts/sessions/components/ViewVnc"));
const Capabilities = React.lazy(() => import("layouts/capabilities/Capabilities"));
const SessionCreation = React.lazy(() => import("layouts/capabilities/components/SessionCreation"));
const ViewVideo = React.lazy(() => import("layouts/histoy-sessions/components/ViewVideo"));
const AdminSettings = React.lazy(() => import("layouts/settings/AdminSettings"));
const UserSettings = React.lazy(() => import("layouts/settings/UserSettings"));
const TunnelLogs = React.lazy(() => import("layouts/tunnel/TunnelLogs"));
const AllOrganisations = React.lazy(() =>
  import("layouts/authFlow/allOrganisations/AllOrganisations")
);
const Onboarding = React.lazy(() => import("layouts/authFlow/onboarding/Onboarding"));
const BuildReport = React.lazy(() => import("layouts/build-report/BuildReport"));
const BuildSessions = React.lazy(() => import("layouts/build-report/BuildSessions"));

const routes =
  FLAVOUR2 === "false"
    ? [
        {
          type: "collapse",
          name: "Dashboard",
          key: "dashboard",
          icon: <Icon fontSize="small">bar_chart</Icon>,
          route: "/dashboard",
          component: <Dashboard />,
          display: true,
        },
        {
          type: "collapse",
          name: "Sessions",
          key: "",
          icon: <Icon fontSize="small">tv</Icon>,
          route: "/",
          component: <Sessions />,
          display: true,
        },
        {
          type: "collapse",
          name: "Capabilities",
          key: "capabilities",
          icon: <Icon fontSize="small">code</Icon>,
          route: "/capabilities",
          component: <Capabilities />,
          display: true,
        },
        {
          type: "collapse",
          name: "History Sessions",
          key: "history-sessions",
          icon: <Icon fontSize="small">videocam</Icon>,
          route: "/history-sessions",
          component: <HistorySessions />,
          display: true,
        },
        {
          type: "collapse",
          name: "Build Report",
          key: "build-report",
          icon: <Icon fontSize="small">videocam</Icon>,
          route: "/build-report",
          component: <BuildReport />,
          display: true,
        },
        {
          type: "collapse",
          name: "Tunnel",
          key: "tunnel",
          icon: <Icon fontSize="small">language</Icon>,
          route: "/tunnel",
          component: <Tunnel />,
          display: true,
        },
        {
          type: "collapse",
          name: "History Tunnel",
          key: "history-tunnel",
          icon: <Icon fontSize="small">work_history</Icon>,
          route: "/history-tunnel",
          component: <HistoryTunnel />,
          display: true,
        },
        {
          type: "divider",
        },
        {
          type: "collapse",
          name: "Admin Settings",
          key: "admin-settings",
          icon: <Icon fontSize="small">settings</Icon>,
          route: "/admin-settings",
          component: <AdminSettings />,
          display: true,
          // localStorage.getItem("role") === "org_admin" ? true : false,
        },
        {
          type: "collapse",
          name: "User Settings",
          key: "user-settings",
          icon: <Icon fontSize="small">manage_accounts_icon</Icon>,
          route: "/user-settings",
          component: <UserSettings />,
          display: true,
          // localStorage.getItem("role") === "org_admin" ? true : false,
        },
        {
          type: "title",
          name: "Build Sessions",
          key: "build-session",
          icon: <Icon fontSize="small">language</Icon>,
          route: "/view-sessions",
          component: <BuildSessions />,
          display: true,
        },
        {
          type: "title",
          name: "Session Creation",
          key: "Session Creation",
          icon: <Icon fontSize="small">language</Icon>,
          route: "/session-creation",
          component: <SessionCreation />,
          display: true,
        },
        {
          type: "title",
          name: "View Session",
          key: "view session",
          icon: <Icon fontSize="small">language</Icon>,
          route: "/view-session",
          component: <ViewVnc />,
          display: true,
        },
        {
          type: "title",
          name: "View Video",
          key: "view video",
          icon: <Icon fontSize="small">language</Icon>,
          route: "/view-video",
          component: <ViewVideo />,
          display: true,
        },
        {
          type: "title",
          name: "View Logs",
          key: "view logs",
          icon: <Icon fontSize="small">language</Icon>,
          route: "/tunnellogs/:instance_id/:name",
          component: <TunnelLogs />,
          display: true,
        },
        // {
        //   type: "collapse",
        //   name: "Profile",
        //   key: "profile",
        //   icon: <Icon fontSize="small">work_history</Icon>,
        //   route: "/profile",
        //   component: <Profile />,
        // },
        // {
        //   type: "collapse",
        //   name: "Tables",
        //   key: "tables",
        //   icon: <Icon fontSize="small">code</Icon>,
        //   route: "/tables",
        //   component: <Tables />,
        // },
        // {
        //   type: "collapse",
        //   name: "Capabilities",
        //   key: "rtl",
        //   icon: <Icon fontSize="small">code</Icon>,
        //   route: "/rtl",
        //   component: <RTL />,
        // },
        // {
        //   type: "collapse",
        //   name: "billing",
        //   key: "billing",
        //   icon: <Icon fontSize="small">videocam</Icon>,
        //   route: "/billing",
        //   component: <Billing />,
        // },
        // {
        //   type: "collapse",
        //   name: "Sign In",
        //   key: "sign-in",
        //   icon: <Icon fontSize="small">login</Icon>,
        //   route: "/authentication/sign-in",
        //   component: <SignIn />,
        // },
        // {
        //   type: "collapse",
        //   name: "Sign Up",
        //   key: "sign-up",
        //   icon: <Icon fontSize="small">assignment</Icon>,
        //   route: "/authentication/sign-up",
        //   component: <SignUp />,
        // },
      ]
    : [
        {
          type: "collapse",
          name: "Dashboard",
          key: "dashboard",
          icon: <Icon fontSize="small">bar_chart</Icon>,
          route: "/dashboard",
          component: <Dashboard />,
          display: true,
        },
        {
          type: "collapse",
          name: "Sessions",
          key: "",
          icon: <Icon fontSize="small">tv</Icon>,
          route: "/",
          component: <Sessions />,
          display: true,
        },
        {
          type: "collapse",
          name: "Capabilities",
          key: "capabilities",
          icon: <Icon fontSize="small">code</Icon>,
          route: "/capabilities",
          component: <Capabilities />,
          display: true,
        },
        {
          type: "collapse",
          name: "History Sessions",
          key: "history-sessions",
          icon: <Icon fontSize="small">videocam</Icon>,
          route: "/history-sessions",
          component: <HistorySessions />,
          display: true,
        },
        {
          type: "collapse",
          name: "Build Report",
          key: "build-report",
          icon: <Icon fontSize="small">videocam</Icon>,
          route: "/build-report",
          component: <BuildReport />,
          display: true,
        },
        {
          type: "divider",
        },
        {
          type: "title",
          name: "Build Sessions",
          key: "build-session",
          icon: <Icon fontSize="small">language</Icon>,
          route: "/view-sessions",
          component: <BuildSessions />,
          display: true,
        },
        {
          type: "title",
          name: "Session Creation",
          key: "Session Creation",
          icon: <Icon fontSize="small">language</Icon>,
          route: "/session-creation",
          component: <SessionCreation />,
          display: true,
        },
        {
          type: "title",
          name: "View Session",
          key: "view session",
          icon: <Icon fontSize="small">language</Icon>,
          route: "/view-session",
          component: <ViewVnc />,
          display: true,
        },
        {
          type: "title",
          name: "View Video",
          key: "view video",
          icon: <Icon fontSize="small">language</Icon>,
          route: "/view-video",
          component: <ViewVideo />,
          display: true,
        },
        {
          type: "title",
          name: "View Logs",
          key: "view logs",
          icon: <Icon fontSize="small">language</Icon>,
          route: "/tunnellogs/:instance_id/:name",
          component: <TunnelLogs />,
          display: true,
        },
      ];

export default routes;
